import { Injectable } from "@angular/core";
import {FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import { PASSWORD_PATTERN_REGEX, PASSWORD_VALIDATION_ERROR } from "../../auth/constants/strings";
import { FieldType } from "../models/field-type";
import { PhoneValidator } from "../models/phone-validator";
import { RegexValidator } from "../models/regex-validator";
import { ValidationError } from "../models/validation-error";
import { FormValidator } from "../models/form-validator";

interface ValidatorData {
    validator: FormValidator;
    error: ValidationError;
}


/**
 * Service that holds custom validators for different input fields
 * Important: to add a new method also add code or error massage to 'ValidationErrorHandlerService'
 */
@Injectable({
    providedIn: 'root'
})
export class InputFieldsValidatorService {

    /**
     * A map-like object that holds the validation data for each field type
     */
    private readonly _TYPE_TO_VALIDATOR_MAP = {
        
        [FieldType.FACEBOOK]: { 
            validator: new RegexValidator("(?:https?:\\/\\/)?(?:www[.])?(?:facebook.com\\/)?([\\/a-zA-Z0-9_-]+)(?:\\?.*)?$"),
            error: "Facebook name. Can be found in the profile. Example: www.facebook.com/bigvu means bigvu."
        },

        [FieldType.INSTAGRAM]: { 
            validator: new RegexValidator("^@?(?:https?:\\/\\/)?(?:www[.])?(?:instagram.com\\/)?([a-zA-Z0-9_.-]+){0,29}$"),
            error: "Please enter a valid Instagram profile url" 
        },

        [FieldType.TWITTER]: { 
            validator: new RegexValidator("^@?(?:https?:\\/\\/)?(?:www[.])?(?:twitter.com\\/)?[A-Za-z0-9_]{1,15}$"),
            error: "Please enter a valid Twitter username" 
        },

        [FieldType.EMAIL]: {
            validator: new RegexValidator("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"),
            error: "Please enter a valid Email"
        },

        [FieldType.LINKEDIN]: {
            validator: new RegexValidator("^(?:https?\\:\\/\\/)(?:www[.])?(?:linkedin.com\\/)(company|in\\/)(.+)$"),
            error: "Please enter a valid LinkedIn profile URL" 
        },

        [FieldType.YOUTUBE]: { 
            validator: new RegexValidator("^(?:https?\\:\\/\\/)(?:www[.])?(?:youtube.com\\/)((channel|c|\\/)|watch|@)(.+)$"),
            error: "Please enter a valid YouTube channel URL" 
        },

        [FieldType.WEBSITE]: { 
            validator: new RegexValidator("[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&/=]*)"),
            error: "Please enter a valid URL" 
        },

        [FieldType.PHONE]: {
            validator: new PhoneValidator(),
            error: "phone"
        },

        [FieldType.TELEGRAM]: { 
            validator: new RegexValidator("(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$"),
            error: "Please enter a valid Telegram channel URL" 
        },

        [FieldType.MESSENGER]: {
            validator: new RegexValidator("(?:https?:\\/\\/)?(?:www[.])?(?:(?:messenger.com\\/t)|(?:m.me))\\/([a-zA-Z0-9_-]+)(?:\\?.*)?$"),
            error: "Please enter a valid Messenger URL"
        },

        [FieldType.PASSWORD]: { 
            validator: new RegexValidator(`${PASSWORD_PATTERN_REGEX}`),
            error: PASSWORD_VALIDATION_ERROR
        },

        [FieldType.URL_DOMAIN]: { 
            validator: new RegexValidator(`^(?!.* .*)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$`),
            error: "Please enter a valid url domain without https://, http:// or /. example: blog.bigvu.com"
        },

        [FieldType.PAGES_URL] : {
            validator: new RegexValidator('^[ 0-9a-zA-Z_-]+$'),
            error: 'Please enter only numbers 0-9, letters a-Z and special characters like  _-'
        },
    };

    getValidator = (type: FieldType): ValidatorFn => {
        return (control: FormControl): ValidationErrors | null => {
            const validatorData = this._TYPE_TO_VALIDATOR_MAP[type];
            return validatorData && !validatorData.validator.logic(control) 
                ? { [type]: validatorData.error }
                : null;
        }
    }

}
