
export enum FieldType {
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    TWITTER = "twitter",
    LINKEDIN = "linkedin",
    YOUTUBE = "youtube",
    WEBSITE = "website",
    PHONE = "phone",
    MESSENGER = "messenger",
    TELEGRAM = "telegram",
    PASSWORD = "password",
    URL_DOMAIN = "urlDomain",
    EMAIL = 'email',
    PAGES_URL = 'pages_url',
    VAT_TAX_ID = 'vat_tax_id'
}
