import { FormControl } from "@angular/forms";
import { FormValidator } from "./form-validator";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export class PhoneValidator implements FormValidator {

    logic = (control: FormControl): boolean => {
        let fullNumber = '';


        if (!control.value) {
            return true;
        }

        if (control.value[0] === '+'){
            fullNumber = control.value.trim();
        } else {
            return false;
        }
        const cleanNumber = fullNumber.replace(new RegExp("[a-z]", "g"), "");
        try {
            let number = phoneUtil.parseAndKeepRawInput(cleanNumber, "");
            return phoneUtil.isValidNumber(number);
        } catch (e){
            return false;
        } 
    }
}
